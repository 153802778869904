import React from "react"
import styled from "styled-components"

import { CONTACT_SELECT_STYLE } from "./styles"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const InputWrapper = styled.div`
  position: relative;
  width :100%;
  &:before {
    content: url( 'data:image/svg+xml;utf-8,<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.00331 14.2111C8.63479 14.9482 7.58298 14.9482 7.21446 14.2111L0.832494 1.44721C0.500044 0.782308 0.983539 -4.53052e-06 1.72692 -4.40159e-06L14.4909 -2.18796e-06C15.2342 -2.05904e-06 15.7177 0.78231 15.3853 1.44721L9.00331 14.2111Z" fill="%23AAAAAA"/></svg>' );
    position: absolute;
    top: calc(50% - 8px);
    right: 24px;
  }
`
const Input = styled.select`
  ${CONTACT_SELECT_STYLE}
`

const Option = styled.option`
`

type Props = {
  value: string
  values: string[]
  setter: Function
  names?: string[]
  isRequired?: boolean
  hasError?: boolean
  placeholder?: string
}

export const ContactSelect: React.VFC<Props> = ({value, values, setter, names, isRequired, hasError, placeholder="選択してください"}) => {

  // valueとnameが同じならvaluesをnamesに利用する
  if (!names) {
    names = values;
  }

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          defaultValue={value}
          onChange={(e) => setter(e.target.value)}
          onBlur={(e) => setter(e.target.value)}
          data-is-not-selected={!value}
          data-is-required={!!isRequired}
          data-is-error={!!hasError}
        >
          <Option value={``}>{placeholder}</Option>
          {
            values.map((v, i) => {
              return (
                <Option key={i} value={names[i]}>{v}</Option>
              )
            })
          }
        </Input>
      </InputWrapper>
    </Wrapper>
  )
}
