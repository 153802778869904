import React from "react"
import Layout from "../../../template/Layout"

/**
 * Material
 */
import Head from "../../../head"
import { ContactDescription } from "../common/ContactDescription"
import { ContactContainer } from "../common/ContactContainer"
import { ContactEtcForm } from "./ContactEtcForm"
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { SectionTitle } from "../../common/SectionTitle"

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

/**
 * Components
 */
export const EtcForm: React.VFC<Props> = (props) => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="その他のお問い合わせ | ネクストライブ株式会社"
        description="ネクストライブ株式会社のその他のお問い合わせフォームです。"
      />
      <ContactContainer>
        <SectionTitle title='CONTACT' subTitle='お問い合わせ' />
        <ContactDescription title="その他のお問い合わせ" step={1} />
        <ContactEtcForm />
      </ContactContainer>
    </Layout>
  )
}
